/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
// import { DateTime } from 'luxon';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import { UserContext } from 'UserContext';
import { doGetAppStatsRef } from 'vendors/firebase/firestore';
// import { indexEvents } from 'vendors/algolia/main';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    display: 'flex',
    marginLeft: 260,
  },
  card: {
    margin: 20,
  },
  cardHeader: {
    backgroundColor: theme.palette.companyPrimary.main,
    paddingBottom: 0,
    textAlign: 'center',
    '& > div > span': {
      color: theme.palette.common.white,
    },
  },
  cardContent: {
    padding: '15px !important',
    transition: 'all 150ms linear',
    border: 'solid 5px white',
    borderRadius: 15,
  },
  cardHighlight: {
    backgroundColor: theme.palette.companyPrimary.main,
  },
  cardValue: {
    padding: 0,
    textAlign: 'center',
  },
  valueHighlight: {
    color: theme.palette.common.white,
  },
  versionCard: {
    width: 180,
  },
  usersCard: {
    width: 150,
  },
  eventsCard: {
    width: 212,
  },
  indexedEventsCard: {
    width: 224,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);
  const { auth } = userContext;

  const [appVersion, setAppVersion] = useState('');
  const [versionChange, setVersionChange] = useState(false);
  // const [userCount, setUserCount] = useState(0);
  // const [userChange, setUserChange] = useState(false);
  // const [organizerCount, setOrganizerCount] = useState(0);
  // const [organizerChange, setOrganizerChange] = useState(false);
  // const [eventCount, setEventCount] = useState(0);
  // const [eventChange, setEventChange] = useState(false);
  // const [indexedEventsCount, setIndexedEventsCount] = useState(0);
  // const [indexedEventsChange, setIndexedEventsChange] = useState(false);

  // const getUniqueActiveEventsCount = (params) => {
  //   indexEvents
  //     .search('', {
  //       ...params,
  //       distinct: 1,
  //     })
  //     .then((responses) => {
  //       const { nbHits } = responses;
  //       setEventCount(nbHits);
  //     });
  // };

  // const getActiveEventsCount = (params) => {
  //   indexEvents
  //     .search('', {
  //       ...params,
  //       distinct: 0,
  //     })
  //     .then((responses) => {
  //       const { nbHits } = responses;
  //       setIndexedEventsCount(nbHits);
  //     });
  // };

  useEffect(() => {
    let unsubscribeStat = null;
    if (auth) {
      // const currDt = DateTime.local();
      // const dt = DateTime.local(currDt.year, currDt.month, currDt.day, 0, 0, 0);
      // const dayInMs = dt.toMillis();
      // const inMins = Math.round(dayInMs / 60000);
      // const params = {
      //   getRankingInfo: true,
      //   page: 0,
      //   hitsPerPage: 0,
      //   filters: `startDate >= ${inMins} AND demo = 0`,
      //   restrictSearchableAttributes: ['id'],
      // };

      doGetAppStatsRef().then((ref) => {
        unsubscribeStat = ref.onSnapshot((doc) => {
          if (doc && doc.exists) {
            const currStat = doc.data();
            if (appVersion !== currStat.version) {
              setAppVersion(currStat.version);
            }
            // if (userCount !== currStat.users) {
            //   setUserCount(currStat.users);
            // }
          }
        });
      });
      // getUniqueActiveEventsCount(params);
      // getActiveEventsCount(params);
    }
    return () => {
      if (unsubscribeStat) {
        unsubscribeStat();
      }
    };
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    setVersionChange(true);
    setTimeout(() => {
      setVersionChange(false);
    }, 500);
  }, [appVersion]);

  // useEffect(() => {
  //   setUserChange(true);
  //   setTimeout(() => {
  //     setUserChange(false);
  //   }, 500);
  // }, [userCount]);

  // useEffect(() => {
  //   setOrganizerChange(true);
  //   setTimeout(() => {
  //     setOrganizerChange(false);
  //   }, 500);
  // }, [organizerCount]);

  // useEffect(() => {
  //   setEventChange(true);
  //   setTimeout(() => {
  //     setEventChange(false);
  //   }, 500);
  // }, [eventCount]);

  // useEffect(() => {
  //   setIndexedEventsChange(true);
  //   setTimeout(() => {
  //     setIndexedEventsChange(false);
  //   }, 500);
  // }, [indexedEventsCount]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} style={{ display: 'inline-flex' }}>
        <Card className={ClassNames(classes.card, classes.versionCard)}>
          <CardHeader title='Version' className={classes.cardHeader} />
          <CardContent
            className={ClassNames(classes.cardContent, {
              [classes.cardHighlight]: versionChange,
            })}
          >
            <Typography
              variant='h4'
              color='textSecondary'
              className={ClassNames(classes.cardValue, {
                [classes.valueHighlight]: versionChange,
              })}
            >
              {appVersion}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
