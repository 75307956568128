/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Error from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { UserContext } from 'UserContext';
import { HOME_URI } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#F2F5F4',
  },
  content: {
    flex: 1,
    padding: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: 0,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  paper: {
    padding: theme.spacing(5),
    maxWidth: 400,
  },
  error: {
    color: '#d12f11',
    paddingTop: theme.spacing(1),
  },
  errorIcon: {
    maxWidth: 36,
    paddingTop: 10,
  },
}));

const LoginPage = React.forwardRef(({ id, history }, ref) => {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    const { onLogin } = userContext;

    if (email === '') {
      setError({ message: 'Please enter your email address' });
    } else if (password === '') {
      setError({ message: 'Please enter your password' });
    } else {
      onLogin(email, password)
        .then(() => {
          history.push(HOME_URI);
        })
        .catch((err) => {
          let error;
          if (err.code === 'auth/invalid-email') {
            error = {
              code: 'auth/invalid-email',
              message: 'Not a valid email address',
            };
          } else {
            error = {
              code: 'auth/invalid-login',
              message: 'Not a valid e-mail and password combination',
            };
          }
          setError(error);
        });
    }
  };

  return (
    <main id={id} ref={ref} className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper} elevation={6}>
          <form
            className={classes.container}
            onSubmit={onSubmit}
            noValidate
            autoComplete='off'
          >
            <Typography variant='h6' align='center' color='inherit'>
              Log In
            </Typography>
            <TextField
              id='email'
              label='Email'
              className={classes.textField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin='normal'
              autoComplete='email'
              autoFocus
            />
            <TextField
              id='password'
              label='Password'
              className={classes.textField}
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              margin='normal'
            />
            {error && (
              <Grid container className={classes.error}>
                <Grid item xs={2} className={classes.errorIcon}>
                  <Error />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant='body1'
                    color='error'
                    className={classes.error}
                    gutterBottom
                  >
                    {error.message}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <span className={classes.button}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                fullWidth
              >
                Log in
              </Button>
            </span>
          </form>
        </Paper>
      </div>
    </main>
  );
});

LoginPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
};

LoginPage.defaultProps = {
  id: '',
};

export default LoginPage;
