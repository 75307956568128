/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { grey, deepOrange } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// light 300
// main  500
// dark  700
// text  500

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: (factor) => `${0.625 * factor}rem`,
  typography: {
    fontsize: 14,
    htmlFontSize: 14,
    fontWeight: 400,
    fontFamily: ['sans-serif'].join(','),
    textRendering: 'optimizeLegibility',
    h1: {
      fontWeight: 900,
      color: 'rgba(0, 0, 0, 0.75)',
    },
    h2: {
      fontWeight: 900,
      color: 'rgba(0, 0, 0, 0.75)',
    },
    h3: {
      fontWeight: 800,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h4: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      paddingBottom: 20,
    },
    h5: {
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.75)',
      paddingBottom: 20,
    },
    h6: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      paddingBottom: 20,
    },
    subtitle1: {
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.75)',
    },
    caption: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    primary: {
      light: '#2a2124',
      main: '#1a1114',
      dark: '#0a0104',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f66110',
      main: '#e65100',
      dark: '#d64100',
      contrastText: '#fff',
    },
    companyPrimary: {
      100: grey[100],
      200: grey[200],
      300: grey[300],
      400: grey[400],
      light: '#2a2124',
      main: '#1a1114',
      dark: '#0a0104',
      contrastText: '#fff',
    },
    companySecondary: {
      100: deepOrange[100],
      200: deepOrange.A100,
      300: deepOrange.A200,
      400: deepOrange.A400,
      light: '#ffa726',
      main: '#f57c00',
      dark: '#e65100',
      contrastText: '#fff',
    },
    company: {
      danger: '#f44336',
      warning: '#f57c00',
      info: '#00acc1',
      grey: grey[600],
      lightgrey: grey[300],
      success: '#4caf50',
    },
  },
});

const withTheme = (Component) => (props) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Component {...props} />
  </MuiThemeProvider>
);

export default withTheme;
