/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */

// Public Pages
export const LOGIN_URI = '/login';
export const DASHBOARD_URI = '/dashboard';
export const HOME_URI = '/dashboard/home';
export const USERS_URI = '/dashboard/users';
export const QUOTES_URI = '/dashboard/quotes';
export const AFFIRMATIONS_URI = '/dashboard/affirmations';
export const SONGS_URI = '/dashboard/songs';
