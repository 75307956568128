/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Rings as Loader } from 'react-loader-spinner';

import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const dangerColor = '#f44336';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    display: 'inline',
  },
  smallInput: {
    padding: '10px 14px',
  },
  padding: {
    paddingRight: 40,
    // paddingLeft: 20,
  },
  label: {
    color: theme.palette.companyPrimary.main,
    '&$focused': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  error: {
    color: dangerColor,
    '&$focused': {
      color: dangerColor,
    },
  },
  focused: {
    outline: 'none',
    borderColor: theme.palette.companyPrimary.main,
  },
  disabled: {},
  closeGap: {
    '& > fieldset > legend > span': {
      padding: 0,
    },
  },
  outlinedInput: {
    '& $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.companyPrimary.main,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.companyPrimary.main,
      borderWidth: 2,
    },
    '&$focused $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: theme.palette.companyPrimary.main,
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  errorOutline: {
    '& $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: dangerColor,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: dangerColor,
      borderWidth: 2,
    },
    '&$focused $notchedOutline': {
      color: theme.palette.companyPrimary.main,
      borderColor: dangerColor,
    },
  },
  notchedOutline: {
    color: theme.palette.companyPrimary.main,
    borderColor: theme.palette.companyPrimary.main,
  },
  iconButton: {
    position: 'absolute',
    top: 21,
    right: 0,
  },
  iconButtonLoc: {
    top: 5,
  },
  iconButtonOutlinedTop: {
    top: 19,
  },
  iconButtonLoadingTop: {
    top: 33,
  },
  iconButtonLoadingRight: {
    right: 10,
  },
  iconButtonSm: {
    top: -7,
    padding: 8,
  },
  iconButtonSmOutlined: {
    top: 0,
    right: 10,
  },
  iconButtonStandard: {
    top: '-7px',
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
  icon: {
    color: grey[400],
    fontSize: 26,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  helperText: {
    color: grey[600],
    fontSize: 14,
    margin: '5px 12px 0',
    '&$focused': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  helperError: {
    color: '#3D4542 !important',
    backgroundColor: '#FDD5CE',
    padding: '8px 12px 5px',
    margin: '-2px 0 0 0',
    borderRadius: '0 0 5px 5px',
    transition: 'all 0.2s cubic-bezier(0.5, 0, 0, 1)',
  },
  multiline: {
    padding: '18.5px 14px 18.5px 0',
  },
});

class CustomTextField extends React.Component {
  constructor() {
    super();

    this.textInput = React.createRef();

    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = (e) => {
    if (e) e.preventDefault();
    const { onCancel, id } = this.props;
    if (onCancel) {
      onCancel(id);
    }
    this.textInput.current.focus();
  };

  render() {
    const {
      classes,
      className,
      disabled,
      error,
      hideOptional,
      id,
      InputProps,
      inputProps,
      label,
      places,
      maxLength,
      onCancel,
      required,
      showLoading,
      size,
      value,
      variant,
      ...other
    } = this.props;
    const labelValue =
      label === '' || variant !== 'outlined' || required ? (
        label
      ) : (
        <span>
          {label}
          {hideOptional ? '' : <small> (optional)</small>}
        </span>
      );

    return (
      <div className={classes.root}>
        <TextField
          {...other}
          variant={variant}
          id={id}
          label={labelValue}
          value={value}
          required={required}
          disabled={disabled}
          error={error}
          margin='normal'
          className={className}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.label,
              focused: classes.focused,
              error: classes.error,
            },
          }}
          InputProps={{
            ...InputProps,
            classes: {
              root: ClassNames({
                [classes.outlinedInput]: !disabled,
                [classes.closeGap]: labelValue === '',
              }),
              // root: ClassNames(classes.outlinedInput, {
              //   [classes.label2]: value === '',
              // }),
              multiline: classes.multiline,
              input: ClassNames(classes.padding, {
                [classes.smallInput]: size === 'sm',
              }),
              focused: classes.focused,
              notchedOutline: ClassNames({
                [classes.notchedOutline]: variant !== 'standard',
              }),
              error: classes.errorOutline,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
              focused: classes.focused,
              error: classes.helperError,
            },
          }}
          // eslint-disable-next-line
          inputProps={{
            ...inputProps,
            ref: this.textInput,
            maxLength,
          }}
        />
        {showLoading && (
          <Loader
            arialLabel='loading'
            color={grey[400]}
            height={26}
            width={26}
            wrapperClass={ClassNames(classes.iconButton, {
              [classes.iconButtonLoc]: places,
              [classes.iconButtonLoadingRight]: places,
              [classes.iconButtonLoadingTop]: variant === 'outlined',
            })}
          />
        )}
        {!showLoading && onCancel && (
          <IconButton
            onClick={this.handleCancel}
            disabled={disabled}
            classes={{
              root: ClassNames(classes.iconButton, {
                [classes.iconButtonLoc]: places,
                [classes.iconButtonSm]: size === 'sm',
                [classes.iconButtonSmOutlined]:
                  size === 'sm' && variant === 'outlined' && label.length > 0,
                [classes.iconButtonStandard]: variant === 'standard',
                [classes.iconButtonHidden]: value === '',
                [classes.iconButtonOutlinedTop]: variant === 'outlined',
              }),
            }}
            tabIndex='-1'
          >
            <ClearIcon className={classes.icon} />
          </IconButton>
        )}
      </div>
    );
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hideOptional: PropTypes.bool,
  id: PropTypes.string,
  InputProps: PropTypes.instanceOf(Object),
  inputProps: PropTypes.instanceOf(Object),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  places: PropTypes.bool,
  maxLength: PropTypes.number,
  onCancel: PropTypes.func,
  required: PropTypes.bool,
  showLoading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  value: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

CustomTextField.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  hideOptional: false,
  id: 'custom-text-input',
  InputProps: null,
  inputProps: null,
  label: '',
  places: false,
  maxLength: 200,
  onCancel: null,
  required: false,
  showLoading: false,
  size: 'lg',
  value: '',
  variant: 'standard',
};

export default withStyles(styles)(CustomTextField);
