/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& $helperText': {
      color: theme.palette.company.grey,
    },
    '&:focus-within $helperText': {
      color: theme.palette.companyPrimary.dark,
    },
  },
  fieldset: {
    display: 'inline-block',
    width: 'calc(100% - 3px)',
    marginTop: 10,
    marginBottom: 8,
    backgroundColor: theme.palette.common.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      padding: 14,
    },
    '&:hover': {
      color: theme.palette.companyPrimary.dark,
      borderColor: theme.palette.companyPrimary.main,
      borderWidth: 1,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.companyPrimary.dark,
      borderColor: theme.palette.companyPrimary.main,
      borderWidth: 2,
    },
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.1875em',
  },
  legend: {
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '2px 5px 3px 5px',
    textAlign: 'left',
    transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    lineHeight: '11px',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-6px',
    },
  },
  error: {
    color: theme.palette.company.danger,
    borderColor: theme.palette.company.danger,
    '&:focus-within': {
      color: theme.palette.company.danger,
      borderColor: theme.palette.company.danger,
    },
  },
  helperText: {
    fontSize: 14,
    margin: '-15px 3px 0 0',
    padding: '6px 12px 5px',
    transition: 'all 0.2s cubic-bezier(0.5, 0, 0, 1)',
    borderRadius: '0 0 5px 5px',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minHeight: '1em',
    lineHeight: '1em',
  },
  helperError: {
    color: '#3D4542 !important',
    backgroundColor: '#FDD5CE',
    padding: '10px 12px',
  },
}));

const FieldSet = React.forwardRef(
  (
    { id, legend, children, className, error, helperText, style, tabIndex },
    ref
  ) => {
    const classes = useStyles();

    const fieldsetRef = React.useRef();

    const onClick = () => {
      fieldsetRef.current.tabIndex = tabIndex;
    };

    return (
      <div id={id} ref={ref} className={classes.root}>
        <fieldset
          ref={fieldsetRef}
          tabIndex={tabIndex}
          onClick={onClick}
          className={ClassNames(classes.fieldset, className, {
            [classes.error]: error,
          })}
          style={style}
        >
          <legend className={classes.legend}>{legend}</legend>
          {children}
        </fieldset>
        {(helperText || error) && (
          <p
            className={ClassNames(classes.helperText, {
              [classes.helperError]: error,
            })}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

FieldSet.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  tabIndex: PropTypes.number,
};

FieldSet.defaultProps = {
  className: '',
  error: false,
  helperText: '',
  id: '',
  style: null,
  tabIndex: 0,
};

export default FieldSet;
