/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
// import { DateTime } from 'luxon';
import { Rings as Loader } from 'react-loader-spinner';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import Error from '@material-ui/icons/Error';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

// import { getFireStorage, getUserMetadata } from 'vendors/firebase/main';
// import { generateInitials, generateDisplayNames } from 'UserContext';
// import {
//   getUserByEmail,
//   getUserByUID,
//   getUserInfoByUID,
//   updateUser,
//   updateUserExtra,
//   doResetUserPassword,
//   doEmailVerification,
// } from 'vendors/firebase/fireauth';
// import { CLOUDINARY } from 'constants/general';
// import { memberPlanDetails } from 'constants/memberships';
// import { capitalize } from 'utilities/utils';

import CustomTextField from 'components/Inputs/CustomTextField';
// import LoadAvatar from 'components/LoadAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 260,
    padding: 20,
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  userProfile: {
    paddingTop: 72,
    position: 'relative',
  },
  searching: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: 225,
    backgroundColor: 'rgba(242, 245, 244, 0.8);',
    zIndex: '10',
    textAlign: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonDisabled: {
    opacity: 0.38,
  },
  progress: {
    color: theme.palette.company.info,
  },
  resultIcon: {
    fontSize: 42,
    float: 'left',
    marginRight: 10,
  },
  success: {
    color: theme.palette.company.success,
    whiteSpace: 'nowrap',
  },
  error: {
    color: theme.palette.company.danger,
  },
  paper: {
    padding: '0 15px 15px',
  },
  cardHeader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    width: '100%',
    borderRadius: '3px',
    padding: '4px 0',
    border: '0',
    marginBottom: '0',
  },
  avatar: {
    margin: '-60px 0 0 25px',
  },
  avatarThumb: {
    position: 'absolute',
    top: 100,
    left: 225,
    zIndex: '10',
  },
  avatarUnknown: {
    margin: '-25px 0 0 25px',
  },
  inputHidden: {
    transform: 'scale(0, 0)',
    opacity: 0,
  },
}));

const UserProfile = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const [newEmail, setNewEmail] = React.useState('');
  const [value, setValue] = useState('');
  const [rawAuth, setRawAuth] = useState(undefined);
  const [userInfo, setUserInfo] = useState(undefined);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [searching, setSearching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const searchInputRef = useRef();

  const onCancel = (e) => {
    setValue('');
    searchInputRef.current.focus();
  };

  const onSearch = (e) => {
    if (e) e.preventDefault();

    const tValue = value.trim();
    if (tValue === '') {
      setSearchError({ message: 'Please enter an email or uid to search' });
      return;
    }

    setSearching(true);
    setSuccess(null);
    setSearchError(null);
    // const func = value.includes('@') ? getUserByEmail : getUserByUID;

    // func(tValue)
    //   .then((auth) => {
    //     const { uid, email, emailVerified, displayName, metadata } = auth;
    //     const creationTime = DateTime.fromHTTP(metadata.creationTime);
    //     const lastSignInTime = DateTime.fromHTTP(metadata.lastSignInTime);
    //     const lastRefreshTime = DateTime.fromHTTP(metadata.lastRefreshTime);
    //     const promises = [];
    //     promises.push(getUserMetadata(uid, displayName));
    //     promises.push(getUserInfoByUID(uid));
    //     return Promise.all(promises)
    //       .then((responses) => {
    //         if (responses[0] && responses[1] && responses[1].exists) {
    //           const user = responses[1].data();
    //           const meta = responses[0];
    //           setSearching(false);
    //           setRawAuth(auth);
    //           setUserInfo({
    //             user,
    //             meta,
    //             uid,
    //             email,
    //             emailVerified,
    //             displayName,
    //             firstName: user.firstName || 'Error',
    //             lastName: user.lastName,
    //             imgSrc: meta ? meta.imgSrc : null,
    //             thumbSrc: meta ? meta.thumbSrc : null,
    //             publicName: meta ? meta.publicName : 'Err',
    //             initials: meta ? meta.initials : 'Err',
    //             isFoundingMember: user.isFoundingMember,
    //             memberPlan: user.memberPlan,
    //             recaptchaScore: user.recaptchaScore || 'n/a',
    //             createdAt: creationTime.toLocaleString(DateTime.DATETIME_FULL),
    //             lastLoginAt: lastSignInTime.toLocaleString(
    //               DateTime.DATETIME_FULL
    //             ),
    //             lastActiveAt: lastRefreshTime.toLocaleString(
    //               DateTime.DATETIME_FULL
    //             ),
    //             error:
    //               meta && responses[1] && responses[1].exists
    //                 ? null
    //                 : { message: 'Failed to retrieve all use information.' },
    //           });
    //           setNewEmail(email);
    //           setEmailVerified(emailVerified);
    //           setNewFirstName(user.firstName);
    //           setNewLastName(user.lastName);
    //           setChangePassword(false);
    //           setNewPassword('');
    //         }
    //       })
    //       .catch((error) => {
    //         setSearching(false);
    //         setSearchError(error);
    //       });
    //   })
    //   .catch((error) => {
    //     setSearching(false);
    //     setSearchError(error);
    //   });
  };

  const onKeyUp = (e) => {
    if (e) e.preventDefault();
    if (e.charCode === 13 || e.key === 'Enter') {
      onSearch();
    } else if (e.charCode === 27 || e.key === 'Escape') {
      onCancel();
    }
  };

  const onUpdate = (e) => {
    if (e) e.preventDefault();
    // const regex = /\s+/gi;
    // const emailCleaned = newEmail.trim().replace(regex, ' ').toLowerCase();
    // const firstNameCleaned = capitalize(
    //   newFirstName.trim().replace(regex, ' ')
    // );
    // const lastNameCleaned = capitalize(newLastName.trim().replace(regex, ' '));
    // const initials = generateInitials(firstNameCleaned, lastNameCleaned);
    // const displayNames = generateDisplayNames(
    //   firstNameCleaned,
    //   lastNameCleaned
    // );
    // const tPassword = newPassword.trim();

    // setSaving(true);
    // setSuccess(null);
    // setUpdateError(null);
    // const newProfile = {
    //   displayName: displayNames.displayName,
    // };
    // if (userInfo.email !== emailCleaned) {
    //   newProfile.email = emailCleaned;
    // }
    // if (userInfo.emailVerified !== emailVerified) {
    //   newProfile.emailVerified = emailVerified;
    // }
    // if (changePassword) {
    //   newProfile.password = tPassword;
    // }

    // const promises = [];
    // promises.push(updateUser(userInfo.uid, newProfile));
    // if (
    //   userInfo.firstName !== displayNames.firstName ||
    //   userInfo.lastName !== displayNames.lastName
    // ) {
    //   promises.push(
    //     updateUserExtra(
    //       userInfo.uid,
    //       displayNames.firstName,
    //       displayNames.lastName
    //     )
    //   );
    //   promises.push(
    //     saveMetadata(
    //       {
    //         initials,
    //         name: displayNames.displayName,
    //         publicName: displayNames.publicName,
    //         pid: userInfo.user.pid,
    //       },
    //       `users/${userInfo.uid}/avatar/metadata`
    //     )
    //   );
    // } else {
    //   promises.push(
    //     Promise.resolve({
    //       firstName: displayNames.firstName,
    //       lastName: displayNames.lastName,
    //     })
    //   );
    // }
    // return Promise.all(promises)
    //   .then((responses) => {
    //     if (responses[0]) {
    //       const respAuth = responses[0];
    //       const respUser = responses[1];
    //       const { uid, email, emailVerified, displayName, metadata } = respAuth;
    //       const creationTime = DateTime.fromHTTP(metadata.creationTime);
    //       const lastSignInTime = DateTime.fromHTTP(metadata.lastSignInTime);
    //       const lastRefreshTime = DateTime.fromHTTP(metadata.lastRefreshTime);
    //       getUserMetadata(uid, displayName)
    //         .then((meta) => {
    //           setRawAuth(respAuth);
    //           setUserInfo((prevUser) => ({
    //             user: {
    //               ...prevUser.user,
    //               ...respUser,
    //             },
    //             meta,
    //             uid,
    //             email,
    //             emailVerified,
    //             displayName,
    //             firstName: respUser.firstName || 'Error',
    //             lastName: respUser.lastName,
    //             imgSrc: meta ? meta.imgSrc : null,
    //             thumbSrc: meta ? meta.thumbSrc : null,
    //             publicName: meta ? meta.publicName : 'Err',
    //             initials: meta ? meta.initials : 'Err',
    //             isFoundingMember: prevUser.user.isFoundingMember,
    //             memberPlan: prevUser.user.memberPlan,
    //             recaptchaScore: prevUser.user.recaptchaScore || 'n/a',
    //             createdAt: creationTime.toLocaleString(DateTime.DATETIME_FULL),
    //             lastLoginAt: lastSignInTime.toLocaleString(
    //               DateTime.DATETIME_FULL
    //             ),
    //             lastActiveAt: lastRefreshTime.toLocaleString(
    //               DateTime.DATETIME_FULL
    //             ),
    //             error:
    //               meta && responses[1] && responses[1].exists
    //                 ? null
    //                 : { message: 'Failed to retrieve all use information.' },
    //           }));
    //           setNewEmail(email);
    //           setEmailVerified(emailVerified);
    //           setNewFirstName(respUser.firstName);
    //           setNewLastName(respUser.lastName);
    //           setChangePassword(false);
    //           setNewPassword('');
    //           setSaving(false);
    //           setSuccess({ message: 'Profile updated successfully.' });
    //         })
    //         .catch((err) => {
    //           setSaving(false);
    //           setUpdateError(err);
    //         });
    //     }
    //   })
    //   .catch((error) => {
    //     setSaving(false);
    //     setUpdateError(error);
    //   });
  };

  // const saveMetadata = async (metadata, destPath) => {
  //   const fs = await getFireStorage();
  //   return new Promise((resolve, reject) => {
  //     const uploadTask = fs
  //       .ref(destPath)
  //       .put(new Blob([JSON.stringify(metadata)]), {
  //         contentType: 'text/plain',
  //         cacheControl: 'no-store',
  //         customMetadata: metadata,
  //       });
  //     return uploadTask.on(
  //       'state_changed',
  //       (snapshot) => {
  //         const uploadProgress = Math.round(
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         );
  //         // eslint-disable-next-line no-console
  //         console.log('Upload progress', uploadProgress);
  //       },
  //       (error) => reject(error),
  //       () => resolve()
  //     );
  //   });
  // };

  const onPasswordReset = () => {
    // setSending(true);
    // doResetUserPassword(userInfo.email, userInfo.displayName)
    //   .then((result) => {
    //     setSending(false);
    //     setSuccess({ message: 'Password reset email sent successfully.' });
    //   })
    //   .catch((error) => {
    //     setSending(false);
    //     setUpdateError(error);
    //   });
  };

  const onEmailVerification = () => {
    // setSending(true);
    // doEmailVerification(userInfo.email, userInfo.displayName)
    //   .then((result) => {
    //     setSending(false);
    //     setSuccess({ message: 'Email verification email sent successfully.' });
    //   })
    //   .catch((error) => {
    //     setSending(false);
    //     setUpdateError(error);
    //   });
  };

  const {
    displayName,
    uid,
    publicName,
    initials,
    email: currEmail,
    emailVerified: currEmailVerified,
    imgSrc,
    thumbSrc,
    recaptchaScore,
    createdAt,
    lastLoginAt,
    lastActiveAt,
    isFoundingMember,
    memberPlan,
  } = userInfo || {
    displayName: null,
    uid: null,
    initials: null,
    imgSrc: null,
    thumbSrc: null,
    recaptchaScore: null,
    createdAt: null,
    lastLoginAt: null,
    lastActiveAt: null,
    isFoundingMember: false,
    memberPlan: null,
  };
  return (
    <div id={id} ref={ref} className={classes.root}>
      <Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.search} elevation={1}>
            <InputBase
              id='value'
              inputRef={searchInputRef}
              className={classes.input}
              placeholder='Search by email or uid'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyUp={onKeyUp}
              autoFocus
            />
            <IconButton
              className={classes.iconButton}
              classes={{
                disabled: ClassNames({
                  [classes.iconButtonDisabled]: value === '',
                }),
              }}
              disabled={value === ''}
              aria-label='Search'
              onClick={onSearch}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              classes={{
                disabled: ClassNames({
                  [classes.iconButtonDisabled]: value === '',
                }),
              }}
              disabled={value === ''}
              aria-label='Clear'
              onClick={onCancel}
            >
              <ClearIcon />
            </IconButton>
          </Paper>
          {searchError && (
            <div className={classes.error} style={{ marginTop: 10 }}>
              <Error className={classes.resultIcon} />
              <Typography
                variant='body2'
                display='block'
                color='inherit'
                style={{ paddingTop: 10 }}
              >
                <strong>{searchError.message}</strong>
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <div className={classes.userProfile}>
        <Fade in={searching} timeout={500}>
          <div className={classes.searching}>
            <Loader
              arialLabel='loading'
              color={theme.palette.companyPrimary.main}
              height={60}
              width={120}
              wrapperStyle={{ display: 'block', transform: 'rotate(45deg)' }}
            />
            <Typography variant='h6' align='center' style={{ paddingTop: 20 }}>
              Searching...
            </Typography>
          </div>
        </Fade>
        <Paper elevation={2} className={classes.paper}>
          <Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.cardHeader}>
                {/* {rawAuth ? (
                  <>
                    {thumbSrc && (
                      <LoadAvatar
                        src={thumbSrc}
                        initials={initials}
                        size='md'
                        transition='slow'
                        className={classes.avatarThumb}
                      />
                    )}
                    <LoadAvatar
                      src={imgSrc}
                      initials={initials}
                      size='lg'
                      transition='slow'
                      className={classes.avatar}
                    />
                  </>
                ) : (
                  <LoadAvatar
                    src={`${CLOUDINARY}v1581102252/hascare/svg/unknown-person.svg`}
                    initials='?'
                    size='md'
                    transition='slow'
                    style={{ margin: '-60px auto 20px 50px' }}
                    className={classes.avatarUnknown}
                  />
                )} */}
                <h2 style={{ marginBottom: 10 }}>{displayName}</h2>
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography variant='body2' display='block'>
                      Public Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <Typography variant='body2' display='block'>
                      <strong>{publicName}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography variant='body2' display='block'>
                      Initials:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <Typography variant='body2' display='block'>
                      <strong>{initials}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography variant='body2' display='block'>
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <Typography variant='body2' display='block'>
                      <strong>{currEmail}</strong>{' '}
                      <span style={{ fontSize: 14 }}>
                        <i>({currEmailVerified ? '' : 'not '}verified)</i>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div>
                <form autoComplete='off'>
                  <Grid container>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        UID:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        <strong>{uid}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Recaptcha Score:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {recaptchaScore}{' '}
                        <span style={{ fontSize: 14, marginLeft: 10 }}>
                          <i>(1 = human, 0 = robot)</i>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Created:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {createdAt}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Last Login:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {lastLoginAt}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Last Active:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {lastActiveAt}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Founding Member:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {isFoundingMember ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Typography variant='body2' display='block'>
                        Membership:
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={10} md={10}>
                      <Typography variant='body2' display='block'>
                        {memberPlanDetails(memberPlan).name}
                      </Typography>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <hr />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginBottom: 8 }}
                    >
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={onPasswordReset}
                        disabled={!rawAuth || saving || sending}
                      >
                        Send password reset
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={onEmailVerification}
                        disabled={!rawAuth || saving || sending}
                      >
                        Send email verification
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <CustomTextField
                        variant='outlined'
                        id='email'
                        label='Email address'
                        value={newEmail}
                        required
                        fullWidth
                        onChange={(e) => setNewEmail(e.target.value)}
                        onCancel={() => setNewEmail('')}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      style={{
                        alignSelf: 'center',
                        marginTop: 8,
                        paddingLeft: 20,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='emailVerified'
                            color='primary'
                            onChange={() => setEmailVerified((prev) => !prev)}
                            checked={emailVerified}
                          />
                        }
                        label='Email verified'
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ paddingRight: 7 }}
                    >
                      <CustomTextField
                        variant='outlined'
                        id='firstName'
                        label='First Name'
                        value={newFirstName}
                        required
                        fullWidth
                        onChange={(e) => setNewFirstName(e.target.value)}
                        onCancel={() => setNewFirstName('')}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ paddingLeft: 7 }}
                    >
                      <CustomTextField
                        variant='outlined'
                        id='lastName'
                        label='Last Name'
                        value={newLastName}
                        fullWidth
                        onChange={(e) => setNewLastName(e.target.value)}
                        onCancel={() => setNewLastName('')}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      style={{
                        alignSelf: 'center',
                        marginTop: 6,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='changePassword'
                            color='primary'
                            onChange={() => setChangePassword((prev) => !prev)}
                            checked={changePassword}
                          />
                        }
                        label='Change password'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <CustomTextField
                        variant='outlined'
                        id='password'
                        label='Enter new password'
                        value={newPassword}
                        required
                        fullWidth
                        onChange={(e) => setNewPassword(e.target.value)}
                        onCancel={() => setNewPassword('')}
                        className={ClassNames({
                          [classes.inputHidden]: !changePassword,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={onUpdate}
                        disabled={!rawAuth || saving || sending}
                      >
                        Update Profile
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      style={{ alignSelf: 'center' }}
                    >
                      {(saving || sending) && (
                        <div style={{ display: 'flex' }}>
                          <CircularProgress
                            size={42}
                            className={classes.progress}
                          />
                          <Typography
                            variant='body2'
                            display='block'
                            color='inherit'
                            style={{ paddingTop: 10, marginLeft: 10 }}
                          >
                            <strong>{sending ? 'Sending' : 'Saving'}...</strong>
                          </Typography>
                        </div>
                      )}
                      {success && (
                        <div className={classes.success}>
                          <Done className={classes.resultIcon} />
                          <Typography
                            variant='body2'
                            display='block'
                            color='inherit'
                            style={{ paddingTop: 10 }}
                          >
                            <strong>{success.message}</strong>
                          </Typography>
                        </div>
                      )}
                      {updateError && (
                        <div className={classes.error}>
                          <Error className={classes.resultIcon} />
                          <Typography
                            variant='body2'
                            display='block'
                            color='inherit'
                            style={{ paddingTop: 10 }}
                          >
                            <strong>{updateError.message}</strong>
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ padding: '30px 15px' }}>
          {rawAuth && (
            <>
              Auth:
              <pre>
                <code>{JSON.stringify(rawAuth, null, '  ')}</code>
              </pre>
            </>
          )}
          {userInfo && (
            <>
              User:
              <pre>
                <code>{JSON.stringify(userInfo.user, null, '  ')}</code>
              </pre>
              Metadata:
              <pre>
                <code>{JSON.stringify(userInfo.meta, null, '  ')}</code>
              </pre>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

UserProfile.propTypes = {
  id: PropTypes.string,
};

UserProfile.defaultProps = {
  id: '',
};

export default UserProfile;
