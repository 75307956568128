/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
// Temporary keep
// return new Promise(resolve => {
//   setTimeout(() => {
//     resolve('Success');
//   }, 1000);
// });
// return new Promise(reject => {
//   setTimeout(() => {
//     reject('Unsuccessful response');
//   }, 1000);
// });
// Promise.reject(new Error('Error something!'));

import { fireapp, getFirestore } from 'vendors/firebase/main';
import { DATA_STATE } from 'constants/general';

// [START Helpers]
const getRandomId = async () => {
  const fs = await getFirestore();
  const doc = await fs
    .collection('operations')
    .doc(process.env.REACT_APP_OPERATIONS_ID)
    .get();
  const { nextRandom, randomFills } = doc.data();
  let newRandomNum;
  if (randomFills.length > 0) {
    newRandomNum = randomFills.shift();
    await fs
      .collection('operations')
      .doc(process.env.REACT_APP_OPERATIONS_ID)
      .set(
        {
          randomFills,
        },
        { merge: true }
      );
    return newRandomNum;
  }
  const increment = fireapp.firestore.FieldValue.increment(1);
  await fs
    .collection('operations')
    .doc(process.env.REACT_APP_OPERATIONS_ID)
    .set(
      {
        nextRandom: increment,
      },
      { merge: true }
    );
  return nextRandom;
};
// [END Helpers]

export const doGetAppStatsRef = async () => {
  const fs = await getFirestore();
  return fs.collection('stats').doc(process.env.REACT_APP_STATS_ID);
};

// [START Quotes]
export const doGetQuotesRef = async () => {
  const fs = await getFirestore();
  return fs.collection('quotes');
};

export const doSaveQuoteDEV = async (id, quote, author) => {
  const nextRandom = await getRandomId();
  console.log('nextRandom:', nextRandom);
};

export const doSaveQuote = async (
  id,
  quote,
  author,
  source,
  tags,
  share,
  moreInfo
) => {
  const fs = await getFirestore();
  const q = {
    quote,
    author,
    source,
    tags,
    share,
    moreInfo,
    status: DATA_STATE.ACTIVE,
  };
  if (id) {
    return fs
      .collection('quotes')
      .doc(id)
      .set(
        {
          ...q,
          updated: fireapp.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }
  const random = await getRandomId();
  return fs.collection('quotes').add({
    ...q,
    random,
    created: fireapp.firestore.FieldValue.serverTimestamp(),
  });
};

export const doRemoveQuote = async (id) => {
  const fs = await getFirestore();
  return fs.collection('quotes').doc(id).delete();
};
// [END Quotes]

// [START Users]
// export const doGetUserRef = async (uid) => {
//   const fs = await getFirestore();
//   return fs.collection('users').doc(uid);
// };

export const doUpdateUser = async (uid, firstName, lastName) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      firstName,
      lastName,
    },
    { merge: true }
  );
};
// [END Users]
