/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';

import { fireauth } from 'vendors/firebase/main';
import {
  doSignInWithEmailAndPassword,
  doSignOut,
} from './vendors/firebase/fireauth';
// import { doGetUserRef } from './vendors/firebase/firestore';
// import { capitalize } from 'utilities/utils';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

const generateInitials = (firstName, lastName) => {
  const fName = firstName ? firstName.trim() : '';
  const lName = lastName ? lastName.trim() : '';
  const firstInitial = fName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
  const lastInitial = lName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
  return firstInitial.join('').concat(lastInitial.join('')).toUpperCase();
};
// const REGEX = /\s+/gi;
// const generateDisplayNames = (firstName, lastName) => {
//   const fName = firstName
//     ? capitalize(firstName.trim().replace(REGEX, ' '))
//     : '';
//   const lName = lastName ? capitalize(lastName.trim().replace(REGEX, ' ')) : '';
//   const lastInitial = lName.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/) || [];
//   const displayName = `${fName} ${lName}`.trim();
//   const publicName = `${fName} ${lastInitial.join('').toUpperCase()}`.trim();
//   return {
//     firstName: fName,
//     lastName: lName,
//     displayName,
//     publicName,
//   };
// };

const INITIAL_USER_STATE = {
  firstName: '',
  lastName: '',
  memberPlan: 0,
  newMsgCount: 0,
  favorites: [],
};

const UserProvider = ({ children }) => {
  const [currentAuth, setCurrentAuth] = React.useState(() => {
    const user = fireauth.currentUser;
    return {
      initializing: !user,
      user,
    };
  });
  const [currentUser, setCurrentUser] = useState(INITIAL_USER_STATE);

  const verifyAuthorization = async (currAuth) => {
    if (currAuth) {
      await fireauth.currentUser
        .getIdTokenResult(true)
        .then(async (idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setCurrentAuth({
              initializing: false,
              user: {
                uid: currAuth.uid,
                displayName: currAuth.displayName,
                email: currAuth.email,
                emailVerified: currAuth.emailVerified,
                // phoneNumber: currAuth.phoneNumber,
                // photoURL: currAuth.photoURL,
              },
            });
          } else {
            doSignOut();
            setCurrentAuth({
              initializing: false,
              user: null,
            });
          }
        });
    } else {
      setCurrentAuth({
        initializing: false,
        user: null,
      });
    }
  };

  useEffect(() => {
    const authUnsubscribe = fireauth.onAuthStateChanged((currAuth) =>
      verifyAuthorization(currAuth)
    );
    return () => {
      authUnsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   let unsubscribeUser = null;
  //   if (currentAuth.user) {
  //     doGetUserRef(currentAuth.user.uid).then((ref) => {
  //       unsubscribeUser = ref.onSnapshot((newdoc) => {
  //         if (newdoc && newdoc.exists) {
  //           const currUser = newdoc.data();
  //           setCurrentUser((prevCurrentUser) => ({
  //             ...prevCurrentUser,
  //             ...currUser,
  //             favorites: currUser.favorites || [],
  //           }));
  //         }
  //       });
  //     });
  //   }
  //   return () => {
  //     if (unsubscribeUser) {
  //       unsubscribeUser();
  //     }
  //   };
  //   // eslint-disable-next-line
  // }, [currentAuth.user]);

  const onLogin = (email, password) =>
    doSignInWithEmailAndPassword(email, password).then(async (currAuth) => {
      await verifyAuthorization(currAuth);
      return Promise.resolve(currAuth);
    });

  const onLogout = () =>
    fireauth.signOut().then(() => {
      setCurrentAuth({
        initializing: false,
        user: null,
      });
    });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider
        value={{
          init: currentAuth.initializing,
          auth: currentAuth.user,
          user: currentUser,
          onLogin,
          onLogout,
        }}
      >
        {children}
      </Provider>
    </Suspense>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  UserContext,
  UserProvider,
  Consumer as UserConsumer,
  generateInitials,
  // generateDisplayNames,
};
