/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { CLOUDINARY } from 'constants/general';

const logo = `${CLOUDINARY}/roboso/roboso_menu_v2.png`;

const SplashPage = ({ message }) => (
  <main className='sp-main'>
    <div className='sp-div'>
      <p className='sp-p'>
        <img className='sp-img' src={logo} alt='Roboso Admin' />
      </p>
      <p className='sp-p'>
        <font className='sp-load'>
          <strong>Initializing...</strong>
        </font>
      </p>
    </div>
  </main>
);

SplashPage.propTypes = {
  message: PropTypes.string,
};

SplashPage.defaultProps = {
  message: null,
};

export default SplashPage;
