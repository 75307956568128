/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import history from 'history/browser';

import { UserContext } from 'UserContext';
import * as routes from 'constants/routes';

import Login from 'views/Login';
import SplashPage from 'views/Splash';
import DashboardPage from 'views/Pages/Dashboard';
import UserProfilePage from 'views/Pages/UserProfile';
import QuotesPage from 'views/Pages/Quotes';

const Dashboard = lazy(() => import('layouts/Dashboard'));

const App = () => {
  const userContext = React.useContext(UserContext);
  const { init, auth } = userContext;

  // console.log('init:', init);
  if (init) {
    return <SplashPage />;
  }

  if (!auth) {
    return <Login history={history} />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<SplashPage />}>
        <Routes>
          <Route path='/dashboard/' element={<Dashboard history={history} />}>
            <Route path='home' element={<DashboardPage />} />
            <Route path='users' element={<UserProfilePage />} />
            <Route path='quotes' element={<QuotesPage />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/dashboard/home" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

App.propTypes = {
  location: PropTypes.instanceOf(Object),
};

App.defaultProps = {
  location: { pathname: routes.DASHBOARD_URI, state: {} },
};

export default App;
