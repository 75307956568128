/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { makeStyles, styled } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import MuiInput from '@mui/material/Input';
import Paper from '@material-ui/core/Paper';
import Tab from '@mui/material/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tabs from '@mui/material/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {
  doGetQuotesRef,
  doSaveQuote,
  doRemoveQuote,
} from 'vendors/firebase/firestore';
import { CATEGORYLIST } from 'constants/general';
import Loading from 'components/Loading';
import FieldSet from 'components/FieldSet';
import Category from 'components/Selects/CategoryChips';
import RichTextEditor from 'components/RichText/Editor';

const Input = styled(MuiInput)`
  width: 42px;
`;

const squareImg =
  'https://res.cloudinary.com/rock-bottom-software/image/upload/c_fit,e_colorize,co_rgb:111111,l_text:kalam___QFONTSIZE___center:__QUOTE__,w___QWIDTH__/e_colorize,co_rgb:555555,l_text:kalam___AFONTSIZE___italic:%7E%20__AUTHOR__/g_center,fl_layer_apply,y_h_add___ASPACE__/v1645674885/pebbles/share_test_square_bh82aq.jpg';
const squareImgNoAuthor =
  'https://res.cloudinary.com/rock-bottom-software/image/upload/c_fit,e_colorize,co_rgb:111111,l_text:kalam___QFONTSIZE___center:__QUOTE__,w___QWIDTH__/v1645674885/pebbles/share_test_square_bh82aq.jpg';
const storyImg =
  'https://res.cloudinary.com/rock-bottom-software/image/upload/c_fit,e_colorize,co_rgb:111111,l_text:kalam___QFONTSIZE___center:__QUOTE__,w___QWIDTH__/e_colorize,co_rgb:555555,l_text:kalam___AFONTSIZE___italic:%7E%20__AUTHOR__/g_center,fl_layer_apply,y_h_add___ASPACE__/v1645674885/pebbles/share_test_story_cw8pdr.jpg';
const storyImgNoAuthor =
  'https://res.cloudinary.com/rock-bottom-software/image/upload/c_fit,e_colorize,co_rgb:111111,l_text:kalam___QFONTSIZE___center:__QUOTE__,w___QWIDTH__/v1645674885/pebbles/share_test_story_cw8pdr.jpg';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'quote', numeric: false, disablePadding: false, label: 'Quote' },
  { id: 'author', numeric: false, disablePadding: false, label: 'Author' },
  { id: 'source', numeric: false, disablePadding: false, label: 'Source' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: 20, fontWeight: '800' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    minHeight: 'calc(100vh - 303px)',
    marginLeft: 260,
    padding: 20,
    width: 'unset',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  row: {
    cursor: 'pointer',
  },
  fabLabel: {
    paddingRight: 5,
    textTransform: 'none',
  },
  margin: {
    margin: `0 ${theme.spacing(1)} ${theme.spacing(1.5)} 0`,
    background: `linear-gradient(45deg, ${theme.palette.companyPrimary.main} 30%, ${theme.palette.companyPrimary.light} 90%)`,
    color: theme.palette.common.white,
    boxShadow: '0 3px 5px 2px rgba(170, 0, 255, .30)',
    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.companyPrimary.dark} 30%, ${theme.palette.companyPrimary.main} 90%)`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },
  },
  extendedIcon: {
    fontSize: '1.5rem',
    marginRight: 5,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowsPerPageSelect: {
    top: 'calc(50% - 15px)',
  },
  quote: {
    position: 'relative',
    textAlign: 'center',
    marginTop: 20,
    // paddingLeft: 'calc(10vw)',
    // paddingRight: 'calc(10vw)',
    paddingTop: 50,
    paddingBottom: 50,
    background: '#111',
    color: '#eee',
    borderRadius: 15,
    fontFamily: 'Kalam, sans-serif',
    fontSize: 32,
  },
  mobile: {
    width: 375,
    height: 800,
    marginRight: 20,
  },
  tablet: {
    width: 768,
    height: 800,
    // paddingLeft: 'calc(10vw)',
    // paddingRight: 'calc(10vw)',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // minWidth: '80vw',
    margin: 0,
    transform: 'translate(-50%, -50%)',
  },
  centerMobile: {
    width: 258,
  },
  centerTablet: {
    width: 614.39,
  },
  filterChip: { float: 'right', width: 130 },
  filterLabel: {
    width: '100%',
  },
  filtered: {
    width: 150,
  },
  filterInputs: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 15,
    },
  },
  quoteInput: {
    marginTop: 20,
    '& textarea': {
      fontSize: '1.4285714285714286rem !important',
      fontFamily: 'sans-serif !important',
      fontWeight: '600 !important',
      lineHeight: '1.6 !important',
    },
  },
  authorInput: {
    marginTop: 20,
    '& input': {
      fontSize: '1.1428571428571428rem; !important',
      fontFamily: 'sans-serif !important',
      fontWeight: '400 !important',
      lineHeight: '1.5 !important',
    },
  },
  sourceInput: {
    marginTop: 20,
    '& input': {
      fontSize: '1.1428571428571428rem; !important',
      fontFamily: 'sans-serif !important',
      fontWeight: '400 !important',
      lineHeight: '1.5 !important',
    },
  },
  inheritedFont: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `quote-preview-tab-${index}`,
    'aria-controls': `quote-preview-tabpanel-${index}`,
  };
}

const Quotes = React.forwardRef(({ id }, ref) => {
  const classes = useStyles();
  const [isQuotesLoaded, setIsQuotesLoaded] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [quoteId, setQuoteId] = useState(null);
  const [quote, setQuote] = useState(null);
  const [author, setAuthor] = useState(null);
  const [source, setSource] = useState(null);
  const [tags, setTags] = useState([]);
  const [moreInfo, setMoreInfo] = useState(null);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('quote');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [preview, setPreview] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterQuote, setFilterQuote] = useState('');
  const [filterAuthor, setFilterAuthor] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [quoteChanged, setQuoteChanged] = useState(false);
  const [socialFontSize, setSocialFontSize] = useState(32);
  const [socialWidth, setSocialWidth] = useState(500);
  const [socialSpace, setSocialSpace] = useState(20);

  const [tabPos, setTabPos] = useState(0);

  const tagsRef = useRef();
  const moreInfoRef = useRef();

  useEffect(() => {
    let unsubscribe = null;
    doGetQuotesRef().then((dataRef) => {
      unsubscribe = dataRef.onSnapshot((querySnapshot) => {
        const dataList = [];
        querySnapshot.forEach((doc) => {
          const docId = doc.id;
          const docQuote = doc.data();
          // console.log('quote:', docQuote);
          dataList.push({
            ...docQuote,
            id: docId,
            source: docQuote.source || '',
          });
        });
        setQuotes(dataList);
        setIsQuotesLoaded(true);
      });
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tabPos === 2) {
      const el = document.getElementById('more-info-id');
      el.innerHTML = moreInfo;
    } else if (tabPos === 1) {
      onSocialRefreah();
    }
    // eslint-disable-next-line
  }, [tabPos]);

  const onSocialRefreah = () => {
    const a = author
      ? encodeURI(author).replaceAll('?', '%3F').replaceAll(',', '%252C')
      : 'Unknown';
    const q = encodeURI(quote)
      .replaceAll('?', '%3F')
      .replaceAll("'", '%27')
      .replaceAll(',', '%252C');
    const elSquare = document.getElementById('social-square-id');
    const imgSquare = (author ? squareImg : squareImgNoAuthor)
      .replace('__QFONTSIZE__', socialFontSize)
      .replace('__AFONTSIZE__', socialFontSize * 0.8)
      .replace('__QWIDTH__', socialWidth)
      .replace('__ASPACE__', socialSpace)
      .replace('__AUTHOR__', a)
      .replace('__QUOTE__', q);
    elSquare.src = imgSquare;
    const elStory = document.getElementById('social-story-id');
    const imgStory = (author ? storyImg : storyImgNoAuthor)
      .replace('__QFONTSIZE__', socialFontSize)
      .replace('__AFONTSIZE__', socialFontSize * 0.8)
      .replace('__QWIDTH__', socialWidth)
      .replace('__ASPACE__', socialSpace)
      .replace('__AUTHOR__', a)
      .replace('__QUOTE__', q);
    elStory.src = imgStory;
  };

  const handleChange = (value) => setQuoteChanged(true);

  const onFilterSearchChange = (e) => {
    setFilterQuote(e.target.value || '');
    setPage(0);
  };
  const onFilterAuthorChange = (e) => {
    setFilterAuthor(e.target.value || '');
    setPage(0);
  };
  const onFilterSourceChange = (e) => {
    setFilterSource(e.target.value || '');
    setPage(0);
  };

  const onClearFilters = () => {
    setFilterQuote('');
    setFilterAuthor('');
    setFilterSource('');
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setDefaultsForQuote = (qLength) => {
    // console.log('qLength:', qLength);
    let defaultFontSize = 32;
    let defaultWidth = 500;
    let defaultSpace;
    if (qLength < 32) {
      defaultSpace = 20;
    } else if (qLength < 60) {
      defaultSpace = 10;
    } else if (qLength < 80) {
      defaultSpace = 0;
    } else if (qLength < 88) {
      defaultSpace = -10;
    } else if (qLength < 118) {
      defaultSpace = -20;
    } else if (qLength < 127) {
      defaultSpace = -30;
    } else if (qLength < 140) {
      defaultSpace = -40;
    } else if (qLength < 180) {
      defaultSpace = -50;
    } else if (qLength < 159) {
      defaultSpace = -60;
    } else if (qLength < 183) {
      defaultSpace = -70;
    } else if (qLength < 195) {
      defaultSpace = -80;
    } else if (qLength < 216) {
      defaultSpace = -90;
      defaultFontSize = 30;
    } else if (qLength < 240) {
      defaultSpace = -100;
      defaultFontSize = 30;
    } else if (qLength < 259) {
      defaultSpace = -110;
      defaultFontSize = 28;
    } else if (qLength < 330) {
      defaultSpace = -120;
      defaultFontSize = 28;
    } else if (qLength < 350) {
      defaultSpace = -130;
      defaultFontSize = 28;
    } else if (qLength < 370) {
      defaultSpace = -140;
      defaultFontSize = 26;
    } else {
      defaultSpace = -150;
      defaultFontSize = 26;
    }
    setSocialFontSize(defaultFontSize);
    setSocialWidth(defaultWidth);
    setSocialSpace(defaultSpace);
  };
  const handleClick = (q) => {
    // console.log('handleClick:', q);
    setQuote(q.quote);
    setAuthor(q.author);
    setSource(q.source);
    setTags(q.tags || []);
    setMoreInfo(q.moreInfo);
    if (q.social) {
      setSocialFontSize(q.social.fontSize);
      setSocialWidth(q.social.width);
      setSocialSpace(q.social.space);
    } else {
      const qLength = q.quote.length + q.author.length + 4;
      setDefaultsForQuote(qLength);
    }
    setQuoteId(q.id);
  };

  const onBack = () => {
    setQuoteId(null);
    setQuote(null);
    setAuthor(null);
    setSource(null);
    setTags([]);
    setMoreInfo(null);
    setSocialFontSize(32);
    setSocialWidth(500);
    setSocialSpace(20);
    setPreview(false);
  };

  const onAdd = () => {
    setQuote('');
    setAuthor('');
    setSource('');
    setTags([]);
    setMoreInfo('');
    setSocialFontSize(32);
    setSocialWidth(500);
    setSocialSpace(20);
    setQuoteId('NEW');
  };

  const onSaveBack = () => {
    onSave();
    onBack();
  };

  const onSave = () => {
    const newTags = tagsRef.current ? tagsRef.current.value.split(',') : tags;
    const mInfo = moreInfoRef.current ? moreInfoRef.current.value : moreInfo;
    doSaveQuote(
      quoteId && quoteId !== 'NEW' ? quoteId : null,
      quote,
      author,
      source,
      newTags,
      {
        fontSize: socialFontSize,
        width: socialWidth,
        space: socialSpace,
      },
      mInfo
    );
    setTags(newTags);
    setMoreInfo(mInfo);
  };

  const onDelete = () => {
    doRemoveQuote(quoteId);
    onBack();
  };

  const togglePreview = () => {
    setPreview((prev) => !prev);
    setTabPos(0);
  };

  const onQuoteChange = (e) => setQuote(e.target.value);
  const onAuthorChange = (e) => setAuthor(e.target.value);
  const onSourceChange = (e) => setSource(e.target.value);

  const FQ_REGEXP = new RegExp(
    filterQuote.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    'i'
  );
  const FA_REGEXP =
    filterAuthor !== 'unknown'
      ? new RegExp(filterAuthor.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
      : new RegExp('^$', 'i');
  const FS_REGEXP = new RegExp(
    filterSource.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    'i'
  );

  const handleTabChange = (event, newPos) => {
    setTabPos(newPos);
  };

  const onSocialFontSizeInputChange = (event) => {
    setSocialFontSize(
      event.target.value === '' ? '' : Number(event.target.value)
    );
  };

  const onSocialWidthInputChange = (event) => {
    setSocialWidth(event.target.value === '' ? '' : Number(event.target.value));
  };

  const onSocialSpaceInputChange = (event) => {
    setSocialSpace(event.target.value === '' ? '' : Number(event.target.value));
  };

  return (
    <Grid container id={id} ref={ref} className={classes.root}>
      {quoteId ? (
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <Button variant='outlined' onClick={onBack}>
              <BackIcon className={classes.iconLeft} /> Back
            </Button>
            <Typography
              variant='body2'
              style={{ marginTop: 10, marginLeft: 50 }}
            >
              {quoteId && quoteId !== 'NEW' ? (
                <span>
                  Quote Id:{' '}
                  <span
                    style={{
                      backgroundColor: '#c8e6c9',
                      padding: 5,
                      borderRadius: 10,
                    }}
                  >
                    {quoteId}
                  </span>
                </span>
              ) : (
                'New Quote'
              )}
            </Typography>
          </div>
          <Button
            variant='contained'
            color='secondary'
            onClick={onSaveBack}
            style={{ marginTop: 20, width: 200 }}
          >
            Save & Back
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={onSave}
            style={{ marginTop: 20, width: 200, marginLeft: 20 }}
          >
            Save
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onDelete}
            style={{ width: 106, marginTop: 20, marginLeft: 50 }}
          >
            Delete
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            onClick={togglePreview}
            style={{ width: 106, marginTop: 20, marginLeft: 50 }}
          >
            {preview ? 'Edit' : 'Preview'}
          </Button>

          {preview ? (
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabPos}
                  onChange={handleTabChange}
                  aria-label='quote previous options'
                >
                  <Tab label='Mobile' {...a11yProps(0)} />
                  <Tab label='Social Media' {...a11yProps(1)} />
                  <Tab label='More Info' {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabPos} index={0}>
                <div style={{ position: 'relative', display: 'flex' }}>
                  <div className={ClassNames(classes.quote, classes.mobile)}>
                    <div
                      className={ClassNames(
                        classes.center,
                        classes.centerMobile
                      )}
                    >
                      <Typography
                        variant='h6'
                        color='inherit'
                        className={classes.inheritedFont}
                      >
                        {quote.split('\n').map((line, index) => (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        ))}
                      </Typography>
                      {author && (
                        <Typography
                          variant='body1'
                          color='inherit'
                          className={classes.inheritedFont}
                        >
                          ~ {author}
                          {source ? (
                            <>
                              , <i>"{source}"</i>
                            </>
                          ) : null}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div className={ClassNames(classes.quote, classes.tablet)}>
                    <div
                      className={ClassNames(
                        classes.center,
                        classes.centerTablet
                      )}
                    >
                      <Typography
                        variant='h6'
                        color='inherit'
                        className={classes.inheritedFont}
                      >
                        {quote.split('\n').map((line, index) => (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        ))}
                      </Typography>
                      {author && (
                        <Typography
                          variant='body1'
                          color='inherit'
                          className={classes.inheritedFont}
                        >
                          ~ {author}
                          {source ? (
                            <>
                              , <i>"{source}"</i>
                            </>
                          ) : null}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabPos} index={1}>
                <div style={{ display: 'flex' }}>
                  <Input
                    value={socialFontSize}
                    onChange={onSocialFontSizeInputChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'social-fontsize-input',
                    }}
                    style={{ margin: 15, width: 100 }}
                  />
                  <Input
                    value={socialWidth}
                    onChange={onSocialWidthInputChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 540,
                      type: 'number',
                      'aria-labelledby': 'social-width-input',
                    }}
                    style={{ margin: 15, width: 100 }}
                  />
                  <Input
                    value={socialSpace}
                    onChange={onSocialSpaceInputChange}
                    inputProps={{
                      step: 1,
                      min: -100,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'social-space-input',
                    }}
                    style={{ margin: 15, width: 100 }}
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={onSocialRefreah}
                    style={{ height: 47 }}
                  >
                    Refresh
                  </Button>
                </div>
                <div style={{ position: 'relative', display: 'flex' }}>
                  <img
                    id='social-square-id'
                    alt='social-square'
                    src=''
                    width={540}
                    height={540}
                    style={{ margin: '10px 20px 0 0', borderRadius: 10 }}
                  />
                  <img
                    id='social-story-id'
                    alt='social-story'
                    src=''
                    width={540}
                    height={960}
                    style={{ margin: '10px 0 0 20px', borderRadius: 10 }}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabPos} index={2}>
                <div id='more-info-id'></div>
              </TabPanel>
            </Box>
          ) : (
            <>
              <TextField
                id='quote-field'
                label='Quote'
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                fullWidth
                minRows={4}
                maxRows={100}
                value={quote}
                onChange={onQuoteChange}
                variant='outlined'
                className={classes.quoteInput}
              />
              <TextField
                id='author-field'
                label='Author'
                InputLabelProps={{
                  shrink: true,
                }}
                value={author}
                onChange={onAuthorChange}
                variant='outlined'
                fullWidth
                className={classes.authorInput}
              />
              <TextField
                id='source-field'
                label='Source'
                InputLabelProps={{
                  shrink: true,
                }}
                value={source}
                onChange={onSourceChange}
                variant='outlined'
                fullWidth
                className={classes.sourceInput}
              />
              <FieldSet legend='Categories'>
                <Category
                  id='quote-tags'
                  ref={tagsRef}
                  tagList={CATEGORYLIST}
                  initTags={tags}
                  fullWidth
                  hasChange={handleChange}
                />
              </FieldSet>
              <FieldSet legend='More Info'>
                <RichTextEditor ref={moreInfoRef} defaultValue={moreInfo} />
              </FieldSet>
            </>
          )}
        </Grid>
      ) : (
        <>
          {isQuotesLoaded ? (
            <>
              <Grid item xs={12}>
                <Fab
                  variant='extended'
                  size='small'
                  color='primary'
                  aria-label='Add Event'
                  className={classes.margin}
                  classes={{ label: classes.fabLabel }}
                  onClick={onAdd}
                >
                  <AddIcon className={classes.extendedIcon} />
                  <span style={{ whiteSpace: 'nowrap' }}>Add Quote</span>
                </Fab>
                <Chip
                  icon={<FilterListIcon style={{ marginLeft: 6 }} />}
                  label={`${showFilter ? 'Hide' : 'Show'} Filter`}
                  clickable
                  color='primary'
                  onClick={() => setShowFilter((prev) => !prev)}
                  onDelete={
                    filterQuote !== '' ||
                    filterAuthor !== '' ||
                    filterSource !== ''
                      ? onClearFilters
                      : null
                  }
                  variant='outlined'
                  classes={{
                    root: ClassNames(classes.filterChip, {
                      [classes.filtered]:
                        filterQuote !== '' ||
                        filterAuthor !== '' ||
                        filterSource !== '',
                    }),
                    label: classes.filterLabel,
                  }}
                />
              </Grid>
              {showFilter && (
                <Grid item xs={12}>
                  <Grid container style={{ paddingBottom: 15 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='filter-search'
                        label='Filter by Quote'
                        placeholder='Start typing quote'
                        variant='outlined'
                        // maxLength={NAME_CHAR_MAX}
                        fullWidth
                        value={filterQuote}
                        onChange={onFilterSearchChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.filterInputs}>
                      <TextField
                        id='filter-by-author'
                        label='Filter by Author (or "unknown")'
                        placeholder='Start typing author'
                        variant='outlined'
                        // maxLength={NAME_CHAR_MAX}
                        fullWidth
                        value={filterAuthor}
                        onChange={onFilterAuthorChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.filterInputs}>
                      <TextField
                        id='filter-by-source'
                        label='Filter by Source'
                        placeholder='Start typing source'
                        variant='outlined'
                        // maxLength={NAME_CHAR_MAX}
                        fullWidth
                        value={filterSource}
                        onChange={onFilterSourceChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby='tableTitle'
                      size='small'
                      aria-label='enhanced table'
                    >
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {stableSort(
                          quotes.filter(
                            (ele) =>
                              ele.quote.match(FQ_REGEXP) &&
                              ele.author.match(FA_REGEXP) &&
                              ele.source.match(FS_REGEXP)
                          ),
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(row)}
                                key={row.id}
                                className={classes.row}
                              >
                                <TableCell
                                  component='th'
                                  id={row.id}
                                  scope='row'
                                  // padding='none'
                                >
                                  {row.quote}
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                  {row.author}
                                </TableCell>
                                <TableCell>{row.source}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component='div'
                    count={
                      quotes.filter(
                        (ele) =>
                          ele.quote.match(FQ_REGEXP) &&
                          ele.author.match(FA_REGEXP) &&
                          ele.source.match(FS_REGEXP)
                      ).length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                      selectIcon: classes.rowsPerPageSelect,
                    }}
                  />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Loading className={classes.root} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
});

Quotes.propTypes = {
  id: PropTypes.string,
};

Quotes.defaultProps = {
  id: '',
};

export default Quotes;
