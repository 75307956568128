/**
 * Copyright 2021-2022 Rock Bottom Software. All Rights Reserved.
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//  import 'firebase/compat/analytics';
//  import 'firebase/compat/performance';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'roboso-app.firebaseapp.com',
  databaseURL: 'https://roboso-app.firebaseio.com',
  projectId: 'roboso-app',
  storageBucket: 'roboso-app.appspot.com',
  // messagingSenderId: "516766330460",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// console.log('CONFIG:', process.env.REACT_APP_FIREBASE_API_KEY);

firebase.initializeApp(config);

const fireauth = firebase.auth();
// const fireperf = firebase.performance();

const dynamicImports = {
  firestorage: null,
  firestore: null,
  firefunc: null,
};
const getFireStorage = async () => {
  if (dynamicImports.firestorage) {
    return dynamicImports.firestorage;
  }
  return Promise.all([import('firebase/compat/storage')]).then(() => {
    dynamicImports.firestorage = firebase.storage();
    return dynamicImports.firestorage;
  });
};
const getFirestore = async () => {
  if (dynamicImports.firestore) {
    return dynamicImports.firestore;
  }
  return Promise.all([import('firebase/compat/firestore')]).then(() => {
    dynamicImports.firestore = firebase.firestore();
    return dynamicImports.firestore;
  });
};
const getFireFunctions = async () => {
  if (dynamicImports.firefunc) {
    return dynamicImports.firefunc;
  }
  return Promise.all([import('firebase/compat/functions')]).then(() => {
    dynamicImports.firefunc = firebase.functions();
    return dynamicImports.firefunc;
  });
};

export {
  fireauth,
  // fireperf,
  getFireStorage,
  getFirestore,
  getFireFunctions,
  firebase as fireapp,
};
