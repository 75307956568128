/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Editor } from '@tinymce/tinymce-react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    fontWeight: theme.typography.body2.fontWeight,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    paddingBottom: 2,
  },
}));

const RichTextEditor = React.forwardRef(({ id, defaultValue }, ref) => {
  const classes = useStyles();
  const [newHtml, setNewHtml] = useState(defaultValue);

  const editorRef = useRef();

  return (
    <div id={id} className={classes.root}>
      <input ref={ref} readOnly value={newHtml} style={{ display: 'none' }} />
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={defaultValue}
        init={{
          selector: 'textarea',
          height: 500,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'image |table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={(newText) => setNewHtml(newText)}
      />
    </div>
  );
});

RichTextEditor.propTypes = {
  defaultValue: PropTypes.string,
  id: PropTypes.string,
};

RichTextEditor.defaultProps = {
  defaultValue: null,
  id: '',
};

export default RichTextEditor;
