/**
 * Copyright 2020-2021 Ian Pedersen. All Rights Reserved.
 */
export const SITEURL =
  process.env.NODE_ENV === 'development'
    ? 'http://www.localhost.com:3000/'
    : 'https://www.amightygoodtime.com/';
export const CLOUDINARY_CLOUDNAME = 'ds6rxxvr3';
export const CLOUDINARY =
  'https://res.cloudinary.com/ds6rxxvr3/image/upload/f_auto,q_auto/';
export const CLOUDINARY_EVENTS = 'sona/events/';
export const CLOUDINARY_ORGANIZERS = 'sona/organizers/';
export const CLOUDINARY_UPLOAD = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload/`;
// TODO: Hide presets from all non-members
export const CLOUDINARY_PRESET = 'as8glwao';
export const CLOUDINARY_ORGANIZER_PRESET = 'rpddpsxx';
export const IMAGESTORAGEURL =
  'https://firebasestorage.googleapis.com/v0/b/stay-out-and-about.appspot.com/o/';

export const NAME_CHAR_MIN = 2;
export const NAME_CHAR_MAX = 100;
export const DESC_CHAR_MIN = 50;
export const DESC_CHAR_MAX = 2000;
export const ORGANIZER_DESC_CHAR_MAX = 500;
export const IMG_SIZE_MB_MAX = 30;
export const PRICE_CHAR_MAX = 50;
export const URL_CHAR_MAX = 200;
export const EMAIL_CHAR_MAX = 200;
export const LOCATION_DETAILS_CHAR_MAX = 500;

const FULL_CATEGORY_LIST = {
  Courage: 'cou',
  Creativity: 'cre',
  Friendship: 'fri',
  Gratitude: 'gra',
  Happiness: 'hap',
  Inspiring: 'ins',
  Leadership: 'lea',
  Love: 'lov',
  Motivational: 'mov',
  'Positive Thinking': 'pos',
  Success: 'suc',
  Teamwork: 'tea',
  Wisdom: 'wis',
};
export const CATEGORYLIST = Object.keys(FULL_CATEGORY_LIST);

export const MODE = {
  INIT: 0,
  LIST: 1,
  CREATE: 2,
  VIEW: 3,
  EDIT: 4,
};

export const DATA_STATE = {
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
  ACTIVE: 4,
  HIDDEN: 5,
  EXPIRED: 6,
  DELETED: 99,
};
export const dataStateLabel = (state) => {
  let stateLabel;
  switch (state) {
    case DATA_STATE.PENDING:
      stateLabel = 'Pending';
      break;
    case DATA_STATE.APPROVED:
      stateLabel = 'Approved';
      break;
    case DATA_STATE.DECLINED:
      stateLabel = 'Declined';
      break;
    case DATA_STATE.ACTIVE:
      stateLabel = 'Active';
      break;
    case DATA_STATE.HIDDEN:
      stateLabel = 'Hidden';
      break;
    case DATA_STATE.DRAFT:
      stateLabel = 'Draft';
      break;
    case DATA_STATE.EXPIRED:
      stateLabel = 'Expired';
      break;
    case DATA_STATE.DELETED:
      stateLabel = 'Deleted';
      break;

    default:
      stateLabel = 'UNKNOWN';
  }
  return stateLabel;
};

export const DEFAULT_REG = {
  link: '',
  email: '',
  phone: '',
  info: '',
};
export const DEFAULT_LOC = {
  inperson: null,
  online: null,
  phone: null,
};
export const DEFAULT_LOC_ONLINE = {
  link: '',
  info: '',
};
export const DEFAULT_EVENT = {
  eid: '',
  name: '',
  featuredPhoto: {
    pid: '',
  },
  date: null,
  location: {
    ...DEFAULT_LOC,
    online: {
      ...DEFAULT_LOC_ONLINE,
    },
  },
  priceType: 'paid',
  price: '',
  register: DEFAULT_REG,
  desc: {
    raw: '',
    search: '',
    length: 0,
  },
  tags: [],
  social: [],
  organizer: {
    id: '',
    name: 'Loading...',
    pid: '',
    url: '',
    phone: '',
    email: '',
    desc: '',
    social: [],
    status: -1,
  },
};

export const DEFAULT_EVENT_ERRORS = {
  name: '',
  date: '',
  organizer: '',
  img: '',
  price: '',
  desc: '',
  tags: '',
  social: '',
  location: '',
  register: '',
};

export const DEFAULT_ORGANIZER = {
  id: '',
  name: '',
  nonProfit: false,
  pid: '',
  url: '',
  phone: '',
  email: '',
  desc: '',
  social: [],
  status: 0,
};

export const DEFAULT_ORGANIZER_ERRORS = {
  name: '',
  pid: '',
  url: '',
  phone: '',
  email: '',
  desc: '',
  social: '',
};
